import { useState, useEffect } from "react";
import itserviceslogo from "../assets/itserviceslogonav.png";
import { useMediaQuery } from "@uidotdev/usehooks";
import { FaXmark, FaBars } from "react-icons/fa6";
import { motion } from "framer-motion";

export default function Header() {
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // HIDING MOBILE NAVBAR WHEN SCREEN SIZE GETS MEDIUM
    if (isMediumDevice) {
      setIsMenuOpen(false);
    }
  }, [isMediumDevice]);

  const navItems = [
    { link: "Home", path: "/" },
    { link: "About", path: "/about" },

    { link: "Contact Us", path: "#contacts" },
  ];

  return (
    <header
      style={{
        backgroundImage: `url("./CoverImage.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="h-screen"
    >
      <>
        <nav
          className={`sm:pl-2 sm:pr-14 md:px-14 px-4 py-2 mx-auto fixed  top-0 right-0 left-0 z-50 transition-all duration-300 ${
            isScrolled ? "bg-white md:shadow-lg lg:shadow-lg" : "bg-transparent"
          }`}
        >
          <div className="text-xl container mx-auto flex justify-between items-center font-medium ">
            <div className="flex justify-between w-full  lg:mr-10 items-center">
              <a
                href="/"
                className="text-2xl font-semibold flex items-center space-x-3"
              >
                <img
                  src={itserviceslogo}
                  alt=""
                  className="w-[140px] inline-block items-center py-1 mt-5"
                />
              </a>

              {/* navitem using map */}
              <ul
                className={`md:flex space-x-12 hidden ${
                  isScrolled ? "text-black" : "text-white"
                }`}
              >
                {navItems.map(({ link, path }) => (
                  <a
                    key={link}
                    href={path}
                    className={`
                    ${
                      isScrolled
                        ? `hover:text-green-800`
                        : `hover:text-green-600`
                    }
                    block  hover:underline  hover:scale-110    `}
                  >
                    {link}
                  </a>
                ))}
              </ul>
            </div>

            {/* inquire button */}
            {/* <div className="space-x-12 hidden md:flex items-center">
              <button className={`py-2 px-4 transition-all duration-300 rounded-lg shadow-2xl ${isScrolled ? "bg-red-600 text-white hover:bg-red-800" : "bg-transparent text-white hover:bg-red-600"}`}>
                Inquire Now
              </button>
            </div> */}

            {/* menu, display only on mobile  */}
            <div className="md:hidden ">
              <button
                onClick={toggleMenu}
                className={`focus:outline-none mr-3   ${
                  isScrolled ? "text-black" : "text-white"
                }`}
              >
                {isMenuOpen ? (
                  <FaXmark
                    className={`w-6 h-6 ${
                      isScrolled ? "text-black" : "text-white"
                    }`}
                  />
                ) : (
                  <FaBars
                    className={`w-6 h-6 ${
                      isScrolled ? "text-black" : "text-white"
                    }`}
                  />
                )}
              </button>
            </div>
          </div>
        </nav>

        {/* Navitem for menu */}
        <div
          className={`space-y-4 px-6 pt-20 pb-5 font-medium ${
            isMenuOpen ? "block fixed top-11 right-0 left-0 z-40" : "hidden"
          }
          ${isScrolled ? "bg-white text-black" : "bg-transparent text-white"}
          
          `}
        >
          {navItems.map(({ link, path }, index) => (
            <div key={link}>
              <a
                href={path}
                className="block hover:text-gray-300 hover:underline"
              >
                {link}
              </a>
              {index < navItems.length && (
                <hr className="border-gray-300 my-2" />
              )}
            </div>
          ))}
        </div>
      </>

      <div className="py-20 h-full flex justify-center items-center">
        {/*IF ELSE */}

        <div>
          <motion.p
            initial={{ x: -500 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-5xl sm:text-6xl md:text-8xl lg:text-9xl text-white font-bold poppins-black-italic beyond-expectations"
          >
            Beyond
          </motion.p>
          <motion.p
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-5xl sm:text-6xl md:text-8xl lg:text-9xl text-green-500 font-bold  pl-12 sm:pl-16 md:pl-20 lg:pl-24 poppins-black-italic beyond-expectations"
          >
            Expectations
          </motion.p>
        </div>

        {/*IF ELSE */}
      </div>
    </header>
  );
}
