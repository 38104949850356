import logowhite from "../assets/fivetwenty white.png";

export default function Footer() {
  return (
    <>
      {/* <div className="h-96 bg-gray-900 w-full flex flex-col items-center justify-center lg:flex-row gap-5 w-200">*/}
      <div id="contacts" className="w-full  bg-neutral-900">
        <div className="max-w-[1240px] mx-auto py-16 px-4 flex flex-col lg:flex-row gap-8 text-gray-300">
          <div className=" h-auto lg:grid lg:grid-cols-2 flex flex-col sm:flex-row lg:w-6/12 gap-5">
            <div className="h-auto sm:w-6/12 lg:h-full lg:w-full">
              <img
                src={logowhite}
                alt=""
                className="md:-ml-4 lg:-mt-[1.2rem] -my-5 md:-my-7 w-full "
              />
              {/* <h6 className="text-green-800 font-bold text-4xl ">FIVETWENTY</h6>
              <h6 className=" font-bold ml-8 text-2xl ">ITServices</h6> */}
              <p className="px-2 md:px-2 font-sans mt-8 text-justify">
                We provide technology for hardware, software applications and
                solutions to ensure alignment with our customer's objectives and
                goals.
              </p>
            </div>
            <div className="px-2 md:px-0 h-auto  sm:w-6/12 lg:h-full lg:w-full">
              <div>
                <h6 className="font-sans font-bold">CONTACTS</h6>
                <span>Email:</span>{" "}
                <span className="font-sans text-green-600 ml-0.4">
                  sales@520itservices.com
                </span>
                <br></br>
                <span>Phone:</span>{" "}
                <span className="font-sans text-green-600 ml-0.4">
                  +63 933 304 5384
                </span>
                <br></br>
                <span>Telephone:</span>{" "}
                <span className="font-sans text-green-600 ml-0.4">
                  8545-4237
                </span>
                <br></br>
                <h6 className="font-sans mt-4 font-bold ">ADDRESS</h6>
                <p className="font-sans text-green-600 leading-tight">
                  214-215 Launchpad Coworking, Commerce Center Bldg. Filinvest
                  City. Alabang Muntinlupa City. Philippines 1781
                </p>
              </div>
            </div>

            {/* <div>
                <h6>
                  ADDRESS
                </h6>

              </div> */}
          </div>
          <div className="px-3 md:px-auto sm:h-72 h-80 w-auto lg:w-6/12 ">
            <iframe
              className="w-full h-full rounded-lg"
              src="https://maps.google.com/maps?q=launchpad%20coworking%20alabang&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className=" bg-green-700 max-sm:py-2 py-5 flex text-white font-light justify-center items-center">
        <p className="max-sm:m-3 mx-auto text-center">
          The names and logos of third party products and companies shown on the
          website and used in the materials are the property of their respective
          owners and may also be trademarks.
        </p>
      </div>
    </>
  );
}
