import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";

import launchpadCoverImage from "../assets/launchpadCoverImage.jpg";
import { whyChooseUs } from "../constants";

export default function About() {
  return (
    <section className="max-w-7xl mx-auto p-8 bg-white">
      <div className="flex flex-col lg:flex-row justify-between items-center gap-8">
        <motion.div
          className="w-full lg:w-1/2"
          initial={{ opacity: 0, x: -50 }}
          viewport={{ once: true }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-4xl font-bold text-black mb-4">About Us</h2>
          <p className="text-lg text-gray-700 mb-8 text-justify">
            At FiveTwenty IT Services, our mission is to provide comprehensive,
            outsourced technology solutions that ensure alignment with our
            customers' objectives and goals. By leveraging our extensive network
            of skilled professionals, we deliver high-quality IT services and
            products without the need for in-house management.
          </p>
        </motion.div>
        <motion.div
          className="w-full lg:w-1/2"
          initial={{ opacity: 0, x: 50 }}
          viewport={{ once: true }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <img
            src={launchpadCoverImage}
            alt="About Us"
            whileInView={"animate"}
            className="rounded-lg shadow-lg"
          />
        </motion.div>
      </div>

      <div className="mt-12">
        <h3 className="text-3xl font-bold text-black mb-8">Why Choose Us?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {whyChooseUs.map((whyChooseUs, index) => (
            <motion.div
              key={index}
              className="p-6 bg-gray-100 rounded-lg shadow-md"
              initial={{ opacity: 0, y: 20 }}
              viewport={{ once: true }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * index, duration: 1 }}
            >
              <h4 className="text-2xl font-bold text-green-800 mb-4">
                {whyChooseUs.title}
              </h4>
              <p className="text-lg text-8ray-700">{whyChooseUs.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="mt-12 text-center text">
        <p className="text-lg text-gray-700">
          Whether you need a new website, custom software, secure cabling, or IT
          training, FiveTwenty IT Services is your trusted outsourcing partner
          in technology. Join our growing list of satisfied customers and let us
          help you achieve your business objectives through innovative IT
          solutions.
        </p>
      </div>
    </section>
  );
}
