// PRODUCTS ICONS
import { IoHardwareChipOutline } from "react-icons/io5";
import { FaLaptop } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { TbRouter } from "react-icons/tb";

// SERVICES ICONS
import { CgBrowser } from "react-icons/cg";
import { PiFileCodeDuotone } from "react-icons/pi";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { PiTreeStructure } from "react-icons/pi";
import { CiServer } from "react-icons/ci";
import { BiCctv } from "react-icons/bi";
import { FaComputer } from "react-icons/fa6";
import { VscTools } from "react-icons/vsc";
import { BsPersonWorkspace } from "react-icons/bs";
import { BsShieldCheck } from "react-icons/bs";

export const OfferedServices = [
  {
    icon: <CgBrowser className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Web Development and Design",
    description:
      "Creating and designing custom, user-friendly websites tailored to your business needs.",
  },
  {
    icon: <PiFileCodeDuotone className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Software Development",
    description:
      "Developing bespoke software solutions to streamline business operations and enhance productivity.",
  },
  {
    icon: (
      <MdOutlineMarkEmailUnread className="stroke-green-800 mt-5 h-24 w-24" />
    ),
    title: "Web and Domain Hosting-Email",
    description:
      "Providing reliable web hosting, domain registration, and professional email solutions for smooth operations.",
  },
  {
    icon: <PiTreeStructure className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Structured Cabling",
    description:
      "Installing and maintaining organized cabling systems for efficient and reliable communication networks within your organization.",
  },
  {
    icon: <CiServer className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Fabrication of Server Cabinet",
    description:
      "Designing and manufacturing custom server cabinets to protect and organize your networking equipment.",
  },
  {
    icon: <BiCctv className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "CCTV Installation and Supply",
    description:
      "Providing and installing high-quality CCTV systems for enhanced security and monitoring.",
  },
  {
    icon: <FaComputer className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Computer and Laptop Repair",
    description:
      "Offering comprehensive repair services for computers and laptops to keep your devices running efficiently.",
  },
  {
    icon: <VscTools className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Electronic Board Repair",
    description:
      "Repairing and maintaining electronic circuit boards to ensure the longevity and functionality of your electronic devices.",
  },
  {
    icon: <BsPersonWorkspace className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "IT Training and Consultancy",
    description:
      "Providing expert IT training and consultancy services to empower your team with the latest technology skills and knowledge.",
  },
  {
    icon: <BsShieldCheck className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Cybersecurity (VAPT)",
    description:
      "Conducting security assessments to identify and fix vulnerabilities, protecting your digital assets.",
  },
];

export const OfferedProducts = [
  {
    icon: <IoHardwareChipOutline className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Hardware Infrastructure",
    products: [
      "Servers and Data Rack Cabinet",
      "Network Switch",
      "Storage and NAS",
      "UPS (Uninterruptible Power Supply)",
    ],
  },
  {
    icon: <FaLaptop className="fill-green-800 mt-5 h-24 w-24" />,
    title: "End User Devices",
    products: [
      "Consumer Computers and Laptops",
      "Printers",
      "Barcode Scanner",
      "CCTV",
    ],
  },
  {
    icon: <MdSecurity className="fill-green-800 mt-5 h-24 w-24" />,
    title: "Software and Security",
    products: [
      " Microsoft Software",
      "Database Software",
      "Endpoint Security Antivirus",
    ],
  },
  {
    icon: <TbRouter className="stroke-green-800 mt-5 h-24 w-24" />,
    title: "Networking and Accessories",
    products: [
      "UTP Cable (for networking)",
      "Firewall Appliance",
      "Point of Sale (POS) System",
      "Computers Parts and Accessories",
    ],
  },
];

export const LogoImagesRight = [
  "./images/logos/netgate-logo.png",
  "./images/logos/synology-logo.png",
  "./images/logos/microsoft-logo.png",
  "./images/logos/3cx-logo.png",
  "./images/logos/tplink-logo.png",
  "./images/logos/cisco-logo.png",
  "./images/logos/kingston-logo.png",
];

export const LogoImagesLeft = [
  "./images/logos/dell-logo.png",
  "./images/logos/apc-logo.png",
  "./images/logos/asus-logo.png",
  "./images/logos/canon-logo.png",
  "./images/logos/dlink-logo.png",
  "./images/logos/epson-logo.png",
  "./images/logos/hp-logo.png",
];

export const whyChooseUs = [
  {
    title: "Expert Network",
    description:
      "We connect you with experienced professionals in various IT domains, ensuring the best solutions and support.",
  },
  {
    title: "Customization",
    description:
      "Our services and products are tailored to meet your specific requirements, ensuring maximum efficiency and effectiveness.",
  },
  {
    title: "Cost-Effective",
    description:
      "Outsourcing through us reduces overhead and operational costs while providing access to top-tier technology solutions.",
  },
  {
    title: "Reliability",
    description:
      "We offer reliable and robust solutions that keep your business running smoothly.",
  },
  {
    title: "Support",
    description:
      "Our dedicated team is always ready to assist you with any IT challenges you may face.",
  },
];
