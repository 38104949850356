// import Styles from "./index.css"

import { motion } from 'framer-motion';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {
  LogoImagesLeft,
  LogoImagesRight,
  OfferedProducts,
  OfferedServices,
} from '../constants';

export default function Home() {
  const servicesVariants = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: (index) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.05 * index,
        ease: 'easeOut',
      },
    }),
  };
  const productsVariants = {
    initial: {
      x: 100,
      opacity: 0,
    },
    animate: (index) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: 1 * index,
        ease: 'easeOut',
      },
    }),
  };

  const carouselOptionsLeft = {
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    loop: true,
    rtlClass: 'owl-rtl',
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
      },
      800: {
        items: 3,
      },
      1200: {
        items: 5,
      },
    },
  };

  const carouselOptionsRight = {
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        rtl: true, // Left-to-right direction
      },
      800: {
        items: 3,
        rtl: true, // Left-to-right direction
      },
      1200: {
        items: 5,
        rtl: true, // Left-to-right direction
      },
    },
  };

  return (
    <>
      {/* PRODUCTS PAGE */}
      <section className="flex flex-col justify-center items-center h-[30rem]">
        <img
          alt="serviceslogo"
          src={'./itserviceslogo.png'}
        />
        <div className="flex justify-center px-10">
          <em className="font-normal text-xl">
            "Ephesians 5:20 "Always giving thanks to God the Father for
            everything, in the name of our Lord Jesus Christ"
          </em>
        </div>
      </section>

      <section className="w-full">
        <div className="max-w-[1240px] mx-auto my-5 py-16 px-4">
          <div className="max-sm:flex max-sm:justify-center">
            <div className="max-sm:flex max-sm:flex-col max-sm:items-center max-sm:justify-center max-sm:border-b-2 max-sm:border-gray-700">
              <h8 className="text-green-800 font-bold text-6xl">PRODUCTS</h8>
              <h6 className="font-bold text-2xl my-2">THAT WE OFFER</h6>
            </div>
          </div>

          <div className="my-8 mx-auto grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:w-11/12 justify-center items-center gap-5">
            {OfferedProducts.map((product, index) => {
              return (
                <>
                  <motion.div
                    variants={servicesVariants}
                    initial="initial"
                    whileInView={'animate'}
                    viewport={{ once: true }}
                    custom={index}
                    className=" rounded-xl border py-4 shadow-2xl flex flex-col px-12 text-center items-center overflow-hidden h-full"
                  >
                    {product.icon}
                    <h6 className="py-5 font-bold text-2xl">{product.title}</h6>
                    {product.products.map((item) => {
                      return (
                        <>
                          <p className="font-semibold py-4 text-lg">{item}</p>
                        </>
                      );
                    })}
                  </motion.div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      {/* SLIDER LOGOS */}
      <section>
        <div className="max-w-[1240px] mx-auto mb-7">
          <OwlCarousel
            className="owl-theme"
            {...carouselOptionsRight}
          >
            {LogoImagesRight.map((logo) => {
              return (
                <>
                  <div>
                    <img
                      className="h-20 md:h-28 lg:h-36 grayscale opacity-50 hover:grayscale-0 hover:opacity-100"
                      src={logo}
                      alt="Logo"
                    />
                  </div>
                </>
              );
            })}
          </OwlCarousel>
        </div>
        <div className="max-w-[1240px] mx-auto ">
          <OwlCarousel
            className="owl-theme"
            {...carouselOptionsLeft}
          >
            {LogoImagesLeft.map((logo) => {
              return (
                <>
                  <div>
                    <img
                      className="h-20 md:h-28 lg:h-36 grayscale opacity-50 hover:grayscale-0 hover:opacity-100"
                      src={logo}
                      alt="Logo"
                    />
                  </div>
                </>
              );
            })}
          </OwlCarousel>
        </div>
      </section>

      {/* SERICES PAGE */}
      <section
        id="services"
        className="w-full"
      >
        <div className="max-w-[1240px] mx-auto my-5 py-16 px-4 ">
          <div className="max-sm:flex max-sm:justify-center max-sm:-mb-7">
            <div className="max-sm:flex max-sm:flex-col max-sm:items-center max-sm:justify-center max-sm:border-b-2 max-sm:border-gray-700">
              <h8 className="text-green-800 font-bold text-6xl">SERVICES</h8>
              <h6 className="font-bold text-2xl my-2">THAT WE OFFER</h6>
            </div>
          </div>

          <div className="my-8 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-5">
            {OfferedServices.map((service, index) => {
              return (
                <>
                  <motion.div
                    variants={servicesVariants}
                    initial="initial"
                    whileInView={'animate'}
                    viewport={{ once: true }}
                    custom={index}
                    className={`py-4 flex flex-col px-12 text-center items-center overflow-hidden h-full hover:text-green-800 ${
                      index === 9 && 'md:col-start-2'
                    }`}
                  >
                    {service.icon}
                    <h6 className="py-3 font-bold text-2xl">{service.title}</h6>
                    <p className="py-2 text-gray-700 text-lg">
                      {service.description}
                    </p>
                  </motion.div>
                </>
              );
            })}
          </div>
          <div className="flex justify-center">
            <img
              src="/LPI-Logo.jpg"
              className="size-96 hover:opacity-60    "
            />
          </div>
        </div>
      </section>
    </>
  );
}
